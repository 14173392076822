/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в Курске ${year}. Соловьиный край`,
  description: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Курске ${year}. Соловьиный край`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Курска. Соловьиный Край`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
  titleOg: () => '',
};

export const astrakhanContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [51.728674, 36.191315],
    zoom: 9,
  },
  locale: { _id: 186 },
  title: 'Соловьиный край',
  subTitle: 'Культурно-туристический портал Курской области.',
  mainBanner: {
    hide: true,
    title: 'Курская область',
    text: 'Соловьиный край России ждет гостей. Здесь есть что посмотреть — стоянки древних людей, ' +
      'старинные храмы и монастыри, усадьбы Афанасия Фета и князей Барятинских, ' +
      'музей Александра Дейнеки и многое другое.',
    links: [
      { link: '/overviews', text: 'Что посмотреть' },
      { link: '/places', text: 'Что посетить' },
      { link: '/restaurants', text: 'Где поесть' },
    ]
  },
  useBvi: envVal({ staging: true, development: false }, false),
  phone: '8 800 200-94-53',
  mail: 'support@welcomekursk.ru',
  agreementLink: '/docs/public_offer_18_01_2023.pdf',
  socials: {
    ok: 'https://ok.ru/group/59637846179866',
    vk: 'https://vk.com/welcomekursk',
    yaDzen: 'https://zen.yandex.ru/id/62330928ffe8ad75b0cad210',
    telegram: 'https://t.me/welcomekursk',
    facebook: 'https://www.facebook.com/%D0%A1%D0%BE%D0%BB%D0%BE%D0%B2%D1%8C%D0%B8%D0%BD%D1%8B%D0%B9' +
      '-%D0%BA%D1%80%D0%B0%D0%B9-110430014815223/?ref=page_internal',
    instagram: 'https://www.instagram.com/welcomekursk/',
  },
  socialTitles: {
    vk: '«Соловьиный край»\nво «вконтакте»',
    telegram: '«Соловьиный край»\nв Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      {
        title: 'Live',
        links: [
          { to: '/streams', title: 'Трансляции' },
          { to: '/artefacts', title: 'Дополненная реальность' },
          { to: '/museums', title: 'Виртуальные музеи' },
        ],
      },
      {
        title: 'Еще',
        links: [
          { title: 'Кафе и рестораны', to: '/restaurants' },
          { title: 'Гостиницы', to: '/hotels' }
        ]
      },
    ],
  },
  footer: {
    title: 'Это Астрахань, не Курск ;)',
    systemName: `© ${year}. Соловьиный край. Культурно-туристический портал Курской области. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Гостиницы', to: '/hotels' },
      { title: 'Рестораны', to: '/restaurants' },
    ]
  },
  analytics: {
    yandex: envVal({ production: '' }, null),
    gtm: envVal({ production: '' }, null),
    pixel: envVal({ production: '' }, null),
    goals: {
      paidVisit: 'events_visit_astrakhan',
      paidBuy: 'buy_ticket_astrakhan',
      freeVisit: 'events_visits_astrakhan',
      freeNoTicketsVisit: 'noactive_free_astrakhan',
      freeBuy: 'freeandzero_astrakhan',
      externalLinkVisit: 'events_visit_redirect_astrakhan',
      externalLinkBuy: 'buy_redirect_astrakhan',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({
      staging: '136452',
    }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'default',
    socialCardIconTg: 'default',
    pages: {
      index: {},
      places: {},
      singlePlace: {},
    },
  },
  seo: {
    main: 'Курск. Соловьиный край',
    pages: {
      index: {
        title: () => 'Соловьиный край - Культурно-туристический портал Курской области',
        description: () => 'Соловьиный Край - Культурно-туристический портал Курской Области. Мероприятия, места, обзоры, маршруты, трансляции, гостиницы, кафе и рестораны',
        titleOg: () => 'Соловьиный край - Культурно-туристический портал Курской области'
      },
      events: {
        title: () => `Афиша Курска ${year}. Куда сходить в Курске развлечься. Купить билеты на мероприятия в Курске. Соловьиный Край`,
        description: () => 'Афиша мероприятий Курска. Куда можно сходить в Курске отдохнуть. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край',
        titleOg: () => '',
      },
      'pushkinskaya-karta/events': {
        title: () => `Пушкинская карта в Курске ${year}. Купить билеты по Пушкинской карте в Курске. Соловьиный Край`,
        description: () => 'Афиша мероприятий по Пушкинской карте. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Соловьиный Край',
      },
      'koncerty-i-shou/events': {
        title: () => `Концерты в Курске ${year}. Афиша концертов в Курске. Соловьиный Край`,
        description: () => 'Расписания концертов в Курске. Купить билеты на концерты в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край',
      },
      'spektakli/events': {
        title: () => `Спектакли в Курске ${year}. Купить билеты на спектакль в Курске. Соловьиный Край`,
        description: () => 'Афиша спектаклей в Курске. Купить билеты на спектакль в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край',
      },
      'detyam/events': {
        title: () => `Детская афиша в Курске ${year}. Куда пойти с ребенком в Курске. Соловьиный Край`,
        description: () => 'Детские мероприятия в Курске. Куда пойти с ребенком в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный Край',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности Курска ${year}. Соловьиный край`,
        description: () => `Интересные и необычные достопримечательности ${year} — описания, цены на билеты, адреса,
         режим работы, фотографии, адреса на карте. Соловьиный край`,
        titleOg: () => ''
      },
      'obuchenie/places': {
        title: () => `Образовательные учреждения и места Курска. Соловьиный Край`,
        description: () => `Образовательные учреждения и места в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
        titleOg: () => '',
      },
      'detyam/places': {
        title: () => `Детские места Курска. Соловьиный Край`,
        description: () => `Места для детей в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
        titleOg: () => '',
      },
      'prochie/places': {
        title: () => `Красивые места Курска. Соловьиный Край`,
        description: () => `Красивые места в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
        titleOg: () => '',
      },
      'na-vozdukhe/places': {
        title: () => `Необычные места Курска. Соловьиный Край`,
        description: () => `Необычные в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
        titleOg: () => '',
      },
      'sport/places': {
        title: () => `Спортивные объекты и места Курска. Соловьиный Край`,
        description: () => `Спортивные объекты и места в Курске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Соловьиный Край`,
        titleOg: () => '',
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      routes: {
        title: () => `Туристические маршруты в Курске ${year}. Соловьиный край`,
        description: () => `Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле.
         Карты туристических маршрутов, описание, необычные идеи для путешествия в Курске ${year}. Соловьиный край`,
        titleOg: () => ''
      },
      overviews: {
        title: () => `Обзоры ${year} — Курск. Соловьиный край`,
        description: () => `Обзоры лучших событий, мест ${year} — Курск. Соловьиный край`,
        titleOg: () => ''
      },
      regionvideos: {
        title: () => `Видео о Курской области, ${year} — Соловьиный край`,
        description: () => `Видео о Курской области, ${year} — Соловьиный край`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы в Курске ${year}. Соловьиный край`,
        description: () => 'Гостиницы в Курске. Подробная информация: описания, адрес,' +
          ' режимы работы, фото, адреса на карте, рейтинги. Соловьиный край',
        titleOg: () => ''
      },
      restaurants: {
        title: () => `Рестораны в Курске ${year}. Соловьиный край`,
        description: () => 'Рестораны в Курске. Подробная информация: описания, режимы работы,' +
          ' фото, адреса на карте, рейтинги. Соловьиный край',
        titleOg: () => ''
      },
      streams: {
        title: () => `Трансляции - Курская область ${year}. Соловьиный край`,
        description: () => `Трансляции - Курская область ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Соловьиный край`,
        titleOg: () => '',
        h1: () => `Трансляции - Курская область ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Соловьиный край',
        description: () => 'Абонементы в Курской области. Соловьиный край',
        titleOg: () => ''
      },
      movies: {
        title: () => `Киноафиша в Курске | Афиша мероприятий с точными датами на ${year} год - Соловьиный край`,
        description: () => `Киноафиша в Курске на ${year} год ✅ Афиша интересных мероприятий в Курской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Соловьиный край`,
        titleOg: () => ''
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время.
         Адрес места проведения — ${entity.address}. Афиша`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName},
         ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}.
          Подробная информация: описание, фото. Официальный сайт — Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
        Фото, режим работы, афиша и события. Соловьиный край`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание,
         адрес на карте, время работы, телефон, фотографии.
          Все туристические места и достопримечательности на сайте — Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание,
         адреса мест на карте, фото. Соловьиный край`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты,
         которые можно исходить пешком или проехать на автомобиле в Курской области. Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Соловьиный край`,
        description: ({ entity }) => `Обзор - ${entity.name}. Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRegionVideo: {
        title: ({ entity }) => `Видео о Курской области, ${year}. ${entity.name}. Соловьиный край`,
        description: ({ entity }) => `Видео о Курской области, ${year}. ${entity.name}. Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Соловьиный край`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Соловьиный край`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на сайте Соловьиный Край`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на сайте Соловьиный Край`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Курск. Соловьиный край`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Купить билеты в Курске. Соловьиный край`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleMovie: {
        title: ({ entity }) => `Фильм ${entity?.name} в Курске  — афиша, цена на билеты,
         расписание, описание, место проведения | Соловьиный край`,
        description: () => `Киноафиша Курска с точным расписанием киносеансов во всех
         кинотеатрах города. Узнать цену и купить билет можно на странице с выбором фильма.
          На сайте можно узнать точное время и дату на сегодня, завтра или в другой день`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => `Пушкинская карта — АИС «Соловьиный край»`,
        description: () => '',
        titleOg: () => `Пушкинская карта — АИС «Соловьиный край»`,
      },
      main: { title: 'Курск. Соловьиный край' },
      museums: {
        title: () => `Виртуальные туры по музеям, ${year} — Курск и Курская область | Соловьиный край`,
        description: () => `Виртуальные туры по музеям Курска и Курской области,
        ${year} — посетите лучшие выставки, не выходя из дома. Соловьиный край.`
      },
      singleMuseum: {
        title: ({ entity }) => ` Виртуальный тур по музею, ${year}. ${entity.name}. ` +
          `Курск и Курская область. Соловьиный край`,
        description: ({ entity }) => `Виртуальный тур по музею, ${year} — посетите выставку, не выходя из дома. ` +
          `Музей — ${entity.name}. Курск и Курская область. Соловьиный край`
      },
      halls: {
        title: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. ` +
          `Курск и Курская область. Соловьиный край.`,
        description: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. ` +
          `Курск и Курская область. Соловьиный край.`
      },
      singleHall: {
        title: ({ entity }) => `Виртуальные концертные залы, ${year},` +
          `${entity.name}. Курск и Курская область. Соловьиный край.`,
        description: ({ entity }) => `Виртуальные концертные залы, ${year}, ` +
          `${entity.name}. Курск и Курская область. Соловьиный край.`
      },
      artefacts: {
        title: () => `Дополненная реальность, ${year} — Курск и Курская область | Соловьиный край`,
        description: () => `Гиды дополненной реальности и интерактивные мероприятия,
        ${year} — Курска и Курской области. Соловьиный край.`
      },
      singleArtefact: {
        title: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Соловьиный край`,
        description: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Соловьиный край`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Официальный сайт — Соловьиный край`,
        description: () => 'Часто задаваемые вопросы. Официальный сайт — Соловьиный край',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} - афиша ${year}. Интересные развлекательные события. Соловьиный край`,
            description: ({ localeName }) => `${localeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места - ${localeName} ${year}. Соловьиный край`,
            description: ({ localeName }) => `Все интересные места и достопримечательности - ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт Соловьиный край`,
            h1: ({ localeName }) => `Места - ${localeName}`,
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны - ${localeName} ${year}. Соловьиный край`,
            description: ({ localeName }) => `Кафе и рестораны - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Соловьиный край`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели - ${localeName} ${year}. Соловьиный край`,
            description: ({ localeName }) => `Гостиницы и отели - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Соловьиный край`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}. Интересные развлекательные события. Соловьиный край`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный край`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Соловьиный край`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Соловьиный край`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Соловьиный край`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Соловьиный край`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Соловьиный край`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Соловьиный край`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Соловьиный край`,
              description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Соловьиный край`,
              description: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Курской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Соловьиный край`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Соловьиный край`,
            description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции - ${placeName} ${year}. Соловьиный край`,
            description: ({ placeName }) => `Трансляции - ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Соловьиный край`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Соловьиный край`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Курской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Соловьиный край`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные трансляции - Курская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Соловьиный край`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Соловьиный край`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции - Курская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Интересные развлекательные события. Соловьиный край`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный край`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Курска на ${formattedDate} года. Интересные развлекательные события в Курске. Соловьиный край`,
            description: ({ formattedDate }) => `Афиша в Курске на ${formattedDate} года. Интересные мероприятия Курска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша в Курске на ${formattedDate}. Кино в Курске - ${formattedDate}. Соловьиный край`,
            description: ({ formattedDate }) => `Кино в Курске - ${formattedDate} ✅ Киноафиша в Курске на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Соловьиный край`,
            description: ({ formattedDate }) => `Трансляции - ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Соловьиный край`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные развлекательные события. Соловьиный край`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные мероприятия - Курская область. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года - Курская область. Соловьиный край`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года - Курская область. Киноафиша на ${formattedDate} года - Курская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} в Курске. Интересные развлекательные события в Курске. Соловьиный край`,
            description: ({ interim, formattedDate }) => `Афиша Курска на ${interim}, ${formattedDate}. Интересные мероприятия Курска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года в Курске. Кино в Курске ${interim}, на ${formattedDate} ${formattedYear} года. Соловьиный край`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино в Курске на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша в Курске ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Соловьиный край`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Соловьиный край`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Соловьиный край`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Курская область. Соловьиный край`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Курская область. Киноафиша на ${interim} ${formattedDate} ${year} года - Курская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} в Курске. Купить билеты по Пушкинской карте в Курске. Соловьиный край`,
              description: () => `Афиша мероприятий по Пушкинской карте Курска. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Соловьиный край`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты в Курске ${year}. Афиша концертов Курска. Соловьиный край`,
              description: () => `Расписания концертов в Курске. Купить билеты на концерты в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный край`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли в Курске ${year}. Купить билеты на спектакль в Курске. Соловьиный край`,
              description: () => `Афиша спектаклей в Курске. Купить билеты на спектакль в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный край`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша в Курске ${year}. Куда пойти с ребенком в Курске. Соловьиный край`,
              description: () => `Детские мероприятия в Курске. Куда пойти с ребенком в Курске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Соловьиный край`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции - ${tagName}. Соловьиный край`,
              description: ({ tagName }) => `Трансляции - ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт Соловьиный край`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${tagName} Курска ${year}. Соловьиный край`,
              description: ({ tagName }) => `${tagName} в Курске. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Соловьиный край`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели - ${tagName} ${year}. Соловьиный край`,
              description: ({ tagName }) => `Гостиницы и отели - ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Соловьиный край`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша в Курске, жанр - ${genreName}. Кино в Курске, жанр - ${genreName}. Соловьиный край`,
            description: ({ genreName }) => `Кино в Курске, жанр - ${genreName} ✅ Киноафиша в Курске, жанр - ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Соловьиный край`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
};
